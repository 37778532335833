<template>
  <div class="content">
    <div class="address-img">
      <img src="../../assets/image/963.png" alt="" />
    </div>
    <p class="lianxi">骨骼发育的特性</p>
    <p class="lianxiadress">根据大数据统计，儿童骨骼发育具有以下五大特性</p>
    <div class="shuju">
      <div>
        <img src="../../assets/image/img_01.png" alt="" />
        <h3>持续性</h3>
        <p>
          骨骼发育是一个持续的动态变化过程，不同的生长周期，各部位的生长速度也有差异。
        </p>
      </div>
      <div>
        <img src="../../assets/image/img_02.png" alt="" />
        <h3>影响性</h3>
        <p>
          骨骼发育过程中一旦出现问题，必然会对孩子的身高身形及健康产生长期影响。
        </p>
      </div>
      <div>
        <img src="../../assets/image/img_03.png" alt="" />
        <h3>相关性</h3>
        <p>
          骨骼之间彼此关联互相牵涉，某一部分出现问题，必然会影响其他的部位。
        </p>
      </div>
      <div>
        <img src="../../assets/image/img_04.png" alt="" />
        <h3>生长不可逆性</h3>
        <p>骨骼的生长发育是绝对的不可逆过程，不要因为“忽视” 错失“发育”良机。</p>
      </div>
      <div>
        <img src="../../assets/image/img_05.png" alt="" />
        <h3>美观性</h3>
        <p>
          儿童青少年时期骨骼生长快，变化快，良好的骨骼力线是决定孩子身高身形的重要因素。
        </p>
      </div>
    </div>
    <p class="lianxi">骨骼测评的组成部分</p>
    <p class="lianxiadress">测评涵盖全身90%的骨骼，数据更全面</p>
    <div class="xiangmu">
      <div @click="tiaozhuan('zubu')">
        <img src="../../assets/image/img_zb.png" alt="" />
        <h3>足部发育测评</h3>
        <p>
          通过对足部在静态以及动态下所采集的130多组数据进行组合分析，对比正常的发育指数，给出足弓及足跟发育的建议，指导健康发育。
        </p>
        <div>
          共5项测评
          <img src="../../assets/image/icon_jt.png" alt="" />
        </div>
      </div>
      <div @click="tiaozhuan('xiazhi')">
        <img src="../../assets/image/img_xz.png" alt="" />
        <h3>下肢发育测评</h3>
        <p>
          通过对下肢在矢状面、冠状面、水平面的不同状态下所采集的数据，并结合其相关的其他部位的测评数据将多组数据进行组合分析，指导健康发育。
        </p>
        <div>
          共6项测评
          <img src="../../assets/image/icon_jt.png" alt="" />
        </div>
      </div>
      <div @click="tiaozhuan('qugan')">
        <img src="../../assets/image/img_qg.png" alt="" />
        <h3>躯干发育测评</h3>
        <p>
          通过对躯干在不同的体位下进行的活动度实时数据采集，并结合其相关的其他部位的测评数据，将多组数据进行组合分析，指导健康发育。
        </p>
        <div>
          共7项测评
          <img src="../../assets/image/icon_jt.png" alt="" />
        </div>
      </div>
      <div @click="tiaozhuan('jichu')">
        <img src="../../assets/image/img_jq.png" alt="" />
        <h3>基础发育测评</h3>
        <p>
          通过对全身形态及数据的采集，获取骨骼发育的基本数据，为其他部位测评结果的判断提供参考依据。
        </p>
        <div>
          共5项测评
          <img src="../../assets/image/icon_jt.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lng: 108.954721, lat: 34.355478 },
      zoom: 17,
    };
  },
  methods: {
    tiaozhuan(item) {
      if (item == "zubu") {
        this.$router.push({
          path: "/shouye/type",
          query: {
            addres: "zubu",
          },
        });
      } else if (item == "xiazhi") {
        this.$router.push({
          path: "/shouye/type",
          query: {
            addres: "xiazhi",
          },
        });
      } else if (item == "qugan") {
        this.$router.push({
          path: "/shouye/type",
          query: {
            addres: "qugan",
          },
        });
      } else {
        this.$router.push({
          path: "/shouye/type",
          query: {
            addres: "jichu",
          },
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.address-img {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
  }
}
.shuju {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 350px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 15%;
  border-bottom: 1px solid #ccc;
  > div {
    width: 20%;
    text-align: center;
    h3 {
      font-size: 18px;
      margin-top: 50px;

      color: rgb(94, 93, 93);
    }
    p {
      width: 100%;
      padding: 0 15%;
      margin-top: 30px;
      color: #9d9d9d;
      text-align: left;
    }
  }
}
.xiangmu {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 600px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 15%;
  margin-bottom: 50px;
  border-bottom: 1px solid #ccc;
  > div {
    position: relative;
    width: 25%;
    height: 500px;
    text-align: center;
    padding-top: 50px;
    margin: 0 20px;
    transition: all 0.5s;

    h3 {
      font-size: 18px;
      margin-top: 50px;

      color: rgb(94, 93, 93);
    }
    p {
      width: 100%;
      padding: 0 13%;
      margin-top: 20px;
      color: #9d9d9d;
      text-align: left;
    }
    div {
      position: absolute;

      width: 100%;
      left: 0;
      bottom: 50px;
      text-align: left;
      font-size: 16px;
      padding: 0 13%;
      img {
        float: right;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
  > div:hover {
    position: relative;
    width: 25%;
    height: 500px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
    transition: all 0.5s;
  }
}
.lianxi {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 25px;
  color: #3f8e0c;
}
.lianxiadress {
  width: 100%;

  text-align: center;
  font-size: 14px;
  color: #9d9d9d;
}
</style>